<template>
  <div class="page">
    <page-title title="提现流水" />
    <withdraw-list/>
  </div>
</template>

<script>
import PageTitle from "../../components/PageTitle.vue";
import WithdrawList from './WithdrawList.vue';
export default {
  components: { PageTitle, WithdrawList },

  data() {
    return {
    };
  },

  computed: {},

  watch: {},

  methods: {
  },
  mounted() {
  },
};
</script>
<style lang='scss' scoped>
.page {
  display: flex;
  flex-direction: column;
  .content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    .form {
      align-self: center;
      width: 509px;
      display: flex;
      flex-direction: column;
      .tip {
        width: 509px;
        padding: 10px 16px;
        background: #e6f7ff;
        border: 1px solid #91d5ff;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
      }
      .item {
        margin-top: 24px;
        margin-left: 14px;
      }
      .line {
        margin-top: 24px;
        background-color: #e9e9e9;
        height: 1px;
      }
      .label {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      .value {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
      }
      .number {
        font-size: 20px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
        line-height: 28px;
      }
      .input {
        width: 340px;
      }
    }
  }
}
</style>