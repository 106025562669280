<template>
  <div class="content">
    <el-form
      inline
      :model="search"
      class="tb-form"
      label-width="90px"
      label-position="left"
    >
      <el-form-item label="推广人：" v-if="isManager">
        <el-input v-model="search.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="日期：">
        <el-date-picker
          v-model="search.date"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="search.status">
          <el-option label="全部" value="" />
          <el-option label="待审核" value="0" />
          <el-option label="审核通过" value="1" />
          <el-option label="驳回" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tablebox">
      <el-table :data="tableData" class="tb-table">
        <el-table-column prop="user_name" label="推广人" />
        <el-table-column prop="created_at" label="日期" />
        <el-table-column label="提现金额">
          <template slot-scope="scope">
              {{scope.row.apply_amount/100}}
          </template>
        </el-table-column>
        <el-table-column prop="current_amount" label="剩余可提现金额"/>
        <el-table-column label="状态">
          <template slot-scope="{ row }">
            <span v-if="row.status === 0">待审核</span>
            <span v-else-if="row.status === 1">审核通过</span>
            <span v-else-if="row.status === 2">驳回</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100px">
          <template slot-scope="{ row }">
            <div v-if="row.status === 0">
              <span class="edit" @click="pass(row)">审核通过</span>
              <div class="space" />
              <span class="edit" @click="reject(row)">驳回</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SellerManageList",
  data() {
    return {
      isManager: false,
      search: {
        name: "",
        date: "",
        status: "",
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0,
      },
    };
  },
  mounted() {
    if (this.$route.query.name) {
      this.isManager = true;
    }
    this.search.name = this.$route.query.name || "";
    this.getList();
  },
  methods: {
    reset() {
      this.search.name = "";
      this.search.status = "";
      this.onSearch();
    },
    onSearch() {
      this.page.no = 0;
      this.getList();
    },
    async getList() {
      let vm = this;
      let { no, size } = this.page;
      let { name, status } = this.search;
      let params = {
        name,
        status,
        page_size: size,
        page_no: no,
      };
      if (this.search.date) {
        params.start_time = this.search.date[0];
        params.end_time = this.search.date[1];
      }
      const url = "admin/seller/apply/list";
      const data = await this.$https.get(url, { params });
      if (!data) return;
      data.total_list.forEach((it) => {
        it.total_amount = vm.$np.divide(it.total_amount, 100);
        it.current_amount = vm.$np.divide(it.current_amount, 100);
        it.earn_amount = vm.$np.divide(it.earn_amount, 100);
      });
      this.page.total = data.total_count;
      this.tableData = data.total_list;
    },
    pageChange(no) {
      this.page.no = no - 1;
      this.getList();
    },
    pass(item) {
      this.$confirm("确定要审核通过提现?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showClose: false,
      })
        .then(() => {
          const url = "admin/seller/apply/check";
          const params = {id:item.id, status: 1 };
          this.$https.post(url, params).then((data) => {
            if (!data) return;
            {
              this.$message.success("审核通过");
              item.status = 1;
            }
          });
        })
        .catch(() => {});
    },
    reject(item) {
      this.$confirm("确定要驳回提现吗?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showClose: false,
      })
        .then(() => {
          const url = "admin/seller/apply/check";
          const params = {id:item.id, status: 2 };
          this.$https.post(url, params).then((data) => {
            if (!data) return;
            {
              this.$message.success("驳回成功");
              item.status = 2;
            }
          });
        })
        .catch(() => {});
    },
    toDetail(row) {
      this.$router.push(`/brand/add?id=${row.id}`);
    },
  },
};
</script>
<style lang='scss' scoped>
  .space {
    display: inline-block;
    width: 1px;
    height: 14px;
    background: #e9e9e9;
    margin: 8px 8px 0 8px;
  }

</style>